import { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

interface UseAuthOptions {
    redirectTo?: string;
}

const useAuth = ({ redirectTo = "/login" }: UseAuthOptions = {}) => {
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                };

                await axios.get(
                    `${process.env.REACT_APP_BACKEND_APP_URL}/api/check-auth/`,
                    config
                );
            } catch (error: any) {
                if (error.response) {
                    if (error.response.status === 401) {
                        // 未認証ならばログインページにリダイレクト
                        navigate(redirectTo);
                    }
                    console.error("An error occurred:", error.response.data);
                } else if (error.request) {
                    console.error("No response was received:", error.request);
                } else {
                    console.error("A general error occurred:", error.message);
                }
                navigate(redirectTo);
            }
        };

        fetchData();
    }, [navigate, redirectTo]);
};

export default useAuth;
