import React, { useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import useGetApi from "../hooks/useGetApi";
import usePostApi from "../hooks/usePostApi";
import Header from "../partials/Header";
import { Container, Text, Box, Flex, Heading, Button, Spacer } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { SourceDocument } from "../partials/SourceDocument";
import { Question, Reply, ReplySet } from "../types/ThreadDomainType";
import { diff_match_patch, DIFF_DELETE, DIFF_INSERT } from 'diff-match-patch';

const ThreadDetail: React.FC = () => {
    useAuth();
    const dmp = new diff_match_patch();
    const { id } = useParams<{ id?: string }>();
    const threadId = id ? parseInt(id, 10) : null;
    const [threadDetail, setThreadDetail] = useState<any>(null);
    const [loading, setLoadding] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [replies, setReplies] = useState<ReplySet>({ chroma: null, faiss: null });
    const [chromaDiffs, setChromaDiffs] = useState<JSX.Element[] | null>(null);
    const [faissDiffs, setFaissDiffs] = useState<JSX.Element[] | null>(null);
    const [chromaReplyApplied, setChromaReplyApplied] = useState<boolean>(false);
    const [faissReplyApplied, setFaissReplyApplied] = useState<boolean>(false);


    const computeDiffs = (text1: string, text2: string): [number, string][] => {
        const diffs = dmp.diff_main(text1, text2);
        dmp.diff_cleanupSemantic(diffs);
        return diffs;
    };

    const highlightDiffs = (diffs: [number, string][]): JSX.Element[] => {
        let elements: JSX.Element[] = [];
        diffs.forEach(([op, text], index) => {
            if (op === DIFF_DELETE) {
                return;
            }

            // テキストを行ごとに分割
            const lines = text.split('\n');

            lines.forEach((line, lineIndex) => {
                if (lineIndex > 0) {
                    // 最初の行以外の前に改行を追加
                    elements.push(<br key={`${index}-${lineIndex}-br`} />);
                }

                // ハイライトを適用しながら行を追加
                elements.push(
                    <span key={`${index}-${lineIndex}`} style={op === DIFF_INSERT ? { backgroundColor: 'yellow' } : {}}>
                        {line}
                    </span>
                );
            });
        });
        return elements;
    };

    const { fetchData, loading: apiLoading, error: apiError } = useGetApi<any>(
        `${process.env.REACT_APP_BACKEND_APP_URL}/api/thread/${threadId}/`,
        null
    );

    const applyToTeacherData = (replyId: number) => async () => {
        try {
            await postApplyToTeacherData({
                reply_id: replyId
            });
        } catch (error) {
            // エラーハンドリング
            console.error("Error applying to teacher data: ", error);
        }
    }


    const { postData: postApplyToTeacherData, response: postApplyToTeacherResponse, loading: postApplyToTeacherLoading, error: postApplyToTeacherError } = usePostApi<any, any>(
        `${process.env.REACT_APP_BACKEND_APP_URL}/api/thread/apply-to-reply/`,
    );

    useEffect(() => {
        setLoadding(apiLoading);
        setError(apiError);
        // setReplies({ chroma: fetchData.data.chroma_reply, faiss: fetchData.data.faiss_reply });
    }, [fetchData]);


    useEffect(() => {
        if (replies.chroma && replies.faiss) {
            const chromaDiffs = computeDiffs(replies.chroma.text, replies.faiss.text);
            setChromaDiffs(highlightDiffs(chromaDiffs));

            const faissDiffs = computeDiffs(replies.faiss.text, replies.chroma.text);
            setFaissDiffs(highlightDiffs(faissDiffs));
        }
    }, [replies.chroma, replies.faiss]);

    useEffect(() => {
        setChromaReplyApplied(replies.chroma?.appliedToTeacherData || false);
        setFaissReplyApplied(replies.faiss?.appliedToTeacherData || false);
    }, [replies.chroma, replies.faiss]);

    useEffect(() => {
        const fetchAllData = async () => {
            try {
                const data = await fetchData();
                console.log(data);
                setThreadDetail(data);
                let replies = data.questions[0]['replies']
                let chroma_reply = null;
                let faiss_reply = null;

                replies.forEach((reply: Reply) => {
                    if (reply.vectorStoreType === "chroma") {
                        chroma_reply = reply;
                    }
                    if (reply.vectorStoreType === "faiss") {
                        faiss_reply = reply;
                    }
                });
                setReplies({ chroma: chroma_reply, faiss: faiss_reply });
            } catch (error) {
                // エラーハンドリング
                console.error("データの取得中にエラーが発生しました", error);
            }
        };

        fetchAllData();
    }, [])

    return (
        <div>
            <Header />
            <Container maxW="container.xl" py={5}>
                <Text fontSize="xl" mb="4">スレッド詳細</Text>
                {loading ? (
                    <Text>Loading...</Text>
                ) : error ? (
                    <Text color="red.500">Error: {error}</Text>
                ) : (
                    <>
                        <Box border="1px" borderColor="gray.200" p="4" borderRadius="md">
                            <Text fontSize="2xl" mb="4">{threadDetail?.title}</Text>
                            <Text mb="4">作成日: {new Date(threadDetail?.created_at).toLocaleString()}</Text>
                            {/* ここから質問と回答を表示 */}
                            {threadDetail?.questions.map((question: Question) => (
                                <Box key={question.id} mb="5" p="4" boxShadow="base" borderRadius="md">
                                    <Text fontWeight="bold">質問:</Text>
                                    <Text mb="2">{question.text}</Text>
                                    <Text fontSize="sm" color="gray.500">作成日: {new Date(question.created_at).toLocaleString()}</Text>
                                    <Flex direction={['column', 'row']} mt={4} mb={12} gap="20px">
                                        {replies.chroma && (
                                            <Box flex="1" p={4} borderRadius="md" border="1px solid">
                                                <Flex direction={['column', 'row']} mt={1} mb={1} gap="20px">
                                                    <Box><Heading as='h2' size='md'>Chroma 返信</Heading></Box>
                                                    <Spacer />
                                                    <Box><Button size='sm' bg='brand.700' onClick={applyToTeacherData(replies.chroma.id)} isDisabled={replies.chroma.appliedToTeacherData}>{chromaReplyApplied}教師データに採用</Button></Box>
                                                </Flex>
                                                {faissDiffs}
                                            </Box>
                                        )}
                                        {replies.faiss && (
                                            <Box flex="1" p={4} borderRadius="md" border="1px solid">
                                                <Flex direction={['column', 'row']} mt={1} mb={1} gap="20px">
                                                    <Box><Heading as='h2' size='md'>FAISS 返信</Heading></Box>
                                                    <Spacer />
                                                    <Box><Button size='sm' bg='brand.700' onClick={applyToTeacherData(replies.faiss.id)} isDisabled={replies.faiss.appliedToTeacherData}>教師データに採用</Button></Box>
                                                </Flex>
                                                {chromaDiffs}
                                            </Box>
                                        )}
                                    </Flex>
                                    {(replies.chroma && replies.faiss) && (
                                        <Flex direction={['column', 'row']} mt={4} gap="20px">
                                            <Heading flex="1" as='h2' size='md'>参考にしたデータ</Heading>
                                            <Heading flex="1" as='h2' size='md'>参考にしたデータ</Heading>
                                        </Flex>
                                    )}
                                    <Flex direction={['column', 'row']} mt={1} gap="20px">
                                        {replies.chroma && (
                                            <Box flex="1" p={4} borderRadius="md">
                                                {replies.chroma.source_documents.map((source, index) => (
                                                    <SourceDocument key={index} source={source} />
                                                ))}
                                            </Box>
                                        )}
                                        {replies.faiss && (
                                            <Box flex="1" p={4} borderRadius="md">
                                                {replies.faiss.source_documents.map((source, index) => (
                                                    <SourceDocument key={index} source={source} />
                                                ))}
                                            </Box>
                                        )}
                                    </Flex>
                                </Box>
                            ))
                            }
                        </Box >
                    </>
                )
                }
            </Container >
        </div >
    );
};

export default ThreadDetail;
