import React, { useState, useEffect } from "react";
import { Container, Heading, Text, Textarea, Button, Flex, Box, useToast } from "@chakra-ui/react";
import axios from "axios";
import useAuth from "../hooks/useAuth";
import Header from "../partials/Header";
import { Reply, ReplySet } from "../types/ThreadDomainType";
import { diff_match_patch, DIFF_DELETE, DIFF_INSERT } from 'diff-match-patch';

const Dashboard: React.FC = () => {
    useAuth();

    const [text, setText] = useState("");
    const [replies, setReplies] = useState<ReplySet>({ chroma: null, faiss: null });
    const [chromaDiffs, setChromaDiffs] = useState<JSX.Element[] | null>(null);
    const [faissDiffs, setFaissDiffs] = useState<JSX.Element[] | null>(null);
    const [submitting, setSubmitting] = useState(false);

    const toast = useToast();
    const dmp = new diff_match_patch();

    // 質問をAPIにPOSTし、複数の返答を処理
    const submitQuestion = async () => {
        setSubmitting(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_APP_URL}/api/thread/`,
                { question: text },
                { withCredentials: true }
            );
            if (response.status === 200) {
                console.log("API Response:", response.data);
                setReplies({ chroma: response.data.chroma_reply, faiss: response.data.faiss_reply });
                toast({
                    title: "成功",
                    description: "質問を送信しました。",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "エラー",
                    description: "質問を送信中にエラーが発生しました。",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: "エラー",
                description: "質問を送信中にエラーが発生しました。",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setSubmitting(false); // 最終的に状態を更新してボタンを再度有効に
        }
    };

    const computeDiffs = (text1: string, text2: string): [number, string][] => {
        const diffs = dmp.diff_main(text1, text2);
        dmp.diff_cleanupSemantic(diffs);
        return diffs;
    };

    const highlightDiffs = (diffs: [number, string][]): JSX.Element[] => {
        let elements: JSX.Element[] = [];
        diffs.forEach(([op, text], index) => {
            if (op === DIFF_DELETE) {
                return;
            }

            // テキストを行ごとに分割
            const lines = text.split('\n');

            lines.forEach((line, lineIndex) => {
                if (lineIndex > 0) {
                    // 最初の行以外の前に改行を追加
                    elements.push(<br key={`${index}-${lineIndex}-br`} />);
                }

                // ハイライトを適用しながら行を追加
                elements.push(
                    <span key={`${index}-${lineIndex}`} style={op === DIFF_INSERT ? { backgroundColor: 'yellow' } : {}}>
                        {line}
                    </span>
                );
            });
        });
        return elements;
    };

    useEffect(() => {
        if (replies.chroma && replies.faiss) {
            const chromaDiffs = computeDiffs(replies.chroma.text, replies.faiss.text);
            setChromaDiffs(highlightDiffs(chromaDiffs));

            const faissDiffs = computeDiffs(replies.faiss.text, replies.chroma.text);
            setFaissDiffs(highlightDiffs(faissDiffs));
        }
    }, [replies.chroma, replies.faiss]);

    // source_documentsを表示するコンポーネント
    const SourceDocument: React.FC<{ source: string }> = ({ source }) => (
        <Box mt={2} p={2} border="1px" borderColor="gray.300" borderRadius="md" fontSize="sm">
            <div dangerouslySetInnerHTML={{ __html: source.replace(/\n/g, '<br />') }}></div>
        </Box>
    );

    return (
        <div>
            <Header />
            <Container>
                <Heading as='h2' size='md'>ホーム</Heading>
                <Textarea
                    size="lg"
                    placeholder="ここに質問内容を記入"
                    minHeight="450px"
                    mt={4}
                    bg="brand.100"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />
                <Flex justifyContent="center" mt={4}>
                    <Button bg="brand.500" size="lg" onClick={submitQuestion} isDisabled={submitting}>
                        送信
                    </Button>
                </Flex>
                <Flex direction={['column', 'row']} mt={4} mb={12} gap="20px">
                    {replies.chroma && (
                        <Box flex="1" p={4} borderRadius="md" border="1px solid">
                            <Heading as='h2' size='md'>Chroma 返信</Heading>
                            {faissDiffs}
                        </Box>
                    )}
                    {replies.faiss && (
                        <Box flex="1" p={4} borderRadius="md" border="1px solid">
                            <Heading as='h2' size='md'>FAISS 返信</Heading>
                            {chromaDiffs}
                        </Box>
                    )}
                </Flex>
                {(replies.chroma && replies.faiss) && (
                    <Flex direction={['column', 'row']} mt={4} gap="20px">
                        <Heading flex="1" as='h2' size='md'>参考にしたデータ</Heading>
                        <Heading flex="1" as='h2' size='md'>参考にしたデータ</Heading>
                    </Flex>
                )}
                <Flex direction={['column', 'row']} mt={1} gap="20px">
                    {replies.chroma && (
                        <Box flex="1" p={4} borderRadius="md">
                            {replies.chroma.source_documents.map((source, index) => (
                                <SourceDocument key={index} source={source} />
                            ))}
                        </Box>
                    )}
                    {replies.faiss && (
                        <Box flex="1" p={4} borderRadius="md">
                            {replies.faiss.source_documents.map((source, index) => (
                                <SourceDocument key={index} source={source} />
                            ))}
                        </Box>
                    )}
                </Flex>
            </Container>
        </div>
    );
};

export default Dashboard;