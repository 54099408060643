import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
    Text,
    Box,
    FormControl,
    FormLabel,
    Input,
    Button,
    VStack,
} from "@chakra-ui/react";

const Login: React.FC = () => {
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrorMessage(null);
        console.log(process.env);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_APP_URL}/api/token/`,
                {
                    username,
                    password,
                },
                {
                    withCredentials: true,
                }
            );

            // トークンがHTTP-only Cookieにセットされると仮定して、
            // 認可された状態で/dashboardに遷移
            navigate("/dashboard");
        } catch (error: any) {
            if (error.response && error.response.status === 401) {
                // ステータスコードが 401 の場合
                setErrorMessage(
                    "ログインに失敗しました。ユーザー名またはパスワードが正しくありません。"
                );
            } else {
                setErrorMessage("ログインに失敗しました。");
            }
        }
    };

    return (
        <Box p={4}>
            <VStack spacing={4}>
                <Box fontSize="2xl" fontWeight="semibold">
                    ログイン
                </Box>
                <form onSubmit={handleSubmit}>
                    <FormControl id="email">
                        <FormLabel>ユーザー名</FormLabel>
                        <Input
                            type="username"
                            value={username}
                            onChange={(e) => setUserName(e.target.value)}
                        />
                    </FormControl>
                    <FormControl id="password" mt={4}>
                        <FormLabel>パスワード</FormLabel>
                        <Input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </FormControl>
                    <Button mt={4} type="submit">
                        ログイン
                    </Button>
                    {errorMessage && (
                        <Text color="red.500">{errorMessage}</Text>
                    )}{" "}
                </form>
            </VStack>
        </Box>
    );
};

export default Login;
