import { Text } from "@chakra-ui/react";

interface ErrorProps {
    error: string;
}

const Error: React.FC<ErrorProps> = ({ error }) => {
    return (
        <Text>エラー: {error}</Text>
    );
};

export default Error;