import { useState, useEffect } from 'react';
import { Container, Heading, Link, Button, Flex, Text, Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import axios from "axios";
import useAuth from "../hooks/useAuth";
import useGetApi from "../hooks/useGetApi";
import usePostApi from "../hooks/usePostApi";
import Header from "../partials/Header";
import Loading from "../partials/Loading";
import Error from "../partials/Error";
import { VectorStoreType, SettingVectorStoreType } from "../types/SettingDomainType";
import { NullLiteral } from 'typescript';


const Setting: React.FC = () => {
    const adminURL = process.env.REACT_APP_BACKEND_APP_URL + "/admin/";

    useAuth();
    const [vectorStoreListData, setVectorStoreListData] = useState<VectorStoreType[]>([]);
    const { fetchData: fetchVectorStoreListData, loading: vectorStoreListLoading, error: vectorStoreListError } = useGetApi<Array<VectorStoreType>>(
        `${process.env.REACT_APP_BACKEND_APP_URL}/api/vector-store/`,
        []
    );
    const [settingVectorStoreData, setSettingVectorStoreData] = useState({ chroma_vector_store_id: 1, faiss_vector_store_id: 2 });
    const { fetchData: fetchSettingVectorStoreData, loading: settingVectorStoreLoading, error: settingVectorStoreError } = useGetApi<SettingVectorStoreType>(
        `${process.env.REACT_APP_BACKEND_APP_URL}/api/setting/vector-store/`,
        { chroma_vector_store_id: 1, faiss_vector_store_id: 2 }
    );
    const [activeChromaStoreId, setActiveChromaStoreId] = useState<number>(1);
    const [activeFaissStoreId, setActiveFaissStoreId] = useState<number>(2);

    // usePostApiフックの初期化
    const { postData: postVectorStoreData, loading: postVectorStoreLoading, error: postVectorStoreError } = usePostApi<any, any>(
        `${process.env.REACT_APP_BACKEND_APP_URL}/api/vector-store/`,
    );

    const vectorStoreUpdate = async () => {
        await postVectorStoreData({});
    };

    const { postData: postVectorStoreSettingData, loading: postVectorStoreSettingLoading, error: postVectorStoreSettingError } = usePostApi<any, any>(
        `${process.env.REACT_APP_BACKEND_APP_URL}/api/setting/vector-store/`,
    );

    const vectorStoreSettingUpdate = async (chroma_id: number, faiss_id: number) => {
        await postVectorStoreSettingData({
            chroma_vector_store_id: chroma_id,
            faiss_vector_store_id: faiss_id
        });
        return { chroma_vector_store_id: chroma_id, faiss_vector_store_id: faiss_id };
    };

    const formatDate = (dateString: string): string => {
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        };
        const date = new Date(dateString);
        const dateFormatter = new Intl.DateTimeFormat('ja-JP', options);
        return dateFormatter.format(date).replace(/\//g, '年').replace(' ', '日 ');
    };

    const isActive = (vectorStoreId: number, vectorStoreType: string): boolean => {
        return (vectorStoreType === 'chroma' && activeChromaStoreId === vectorStoreId) ||
            (vectorStoreType === 'faiss' && activeFaissStoreId === vectorStoreId);
    };

    const handleUseVectorStoreSettingUpdate = async (vectorStoreId: number, vectorStoreType: string): Promise<void> => {
        let chromaId: number;
        let faissId: number;
        chromaId = activeChromaStoreId ? activeChromaStoreId : 1;
        faissId = activeFaissStoreId ? activeFaissStoreId : 2;
        if (vectorStoreType === 'chroma') {
            chromaId = vectorStoreId;
        } else if (vectorStoreType === 'faiss') {
            faissId = vectorStoreId;
        }
        const updatedVectorStoreData = await vectorStoreSettingUpdate(chromaId, faissId);
        if (updatedVectorStoreData) {
            setSettingVectorStoreData(updatedVectorStoreData); // 更新されたデータを設定
            setActiveChromaStoreId(updatedVectorStoreData.chroma_vector_store_id);
            setActiveFaissStoreId(updatedVectorStoreData.faiss_vector_store_id);
        }
    }

    useEffect(() => {
        const fetchAllData = async () => {
            try {
                const vectorStoreData = await fetchVectorStoreListData();
                setVectorStoreListData(vectorStoreData);

                const settingData = await fetchSettingVectorStoreData();
                setSettingVectorStoreData(settingData);
                setActiveChromaStoreId(settingData.chroma_vector_store_id);
                setActiveFaissStoreId(settingData.faiss_vector_store_id);
            } catch (error) {
                // エラーハンドリング
                console.error("データの取得中にエラーが発生しました", error);
            }
        };

        fetchAllData();
    }, [])



    return (
        <div>
            <Header />
            <Container>
                <Box mb={10}>
                    <Heading as='h2' size='md'>教師データ更新</Heading>
                    <Flex justifyContent="center" mt={4}>
                        <Button
                            bg="brand.500"
                            size="lg"
                            onClick={vectorStoreUpdate}
                        >
                            更新
                        </Button>
                    </Flex>
                </Box>
                <Box mb={4}>
                    <Heading as='h2' size='md'>教師ベクトルデータ一覧</Heading>
                    {vectorStoreListLoading ? (
                        <Loading />
                    ) : vectorStoreListError ? (
                        <Error error={vectorStoreListError} />  // こちらでエラーメッセージをエラーコンポーネントに渡しています。
                    ) : (
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>ID</Th>
                                    <Th>タイトル</Th>
                                    <Th>メモ</Th>
                                    <Th>タイプ</Th>
                                    <Th>作成日</Th>
                                    <Th>作成者</Th>
                                    <Th></Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {vectorStoreListData.map((vectorStore) => (
                                    <Tr key={vectorStore.id}>
                                        <Td>{vectorStore.id}</Td>
                                        <Td>{vectorStore.title}</Td>
                                        <Td>{vectorStore.note} <Link href={adminURL + "agent/relationvectorstore/" + vectorStore.id + "/change/"} isExternal ml={2} fontSize={8}>編集</Link></Td>
                                        <Td>{vectorStore.vector_store_type}</Td>
                                        <Td>{formatDate(vectorStore.created_at)}</Td>
                                        <Td>{vectorStore.created_by.username}</Td>
                                        <Td textAlign="center">
                                            {isActive(vectorStore.id, vectorStore.vector_store_type) ? (
                                                <Text>利用中</Text>
                                            ) : (
                                                <Button size='xs' onClick={() => handleUseVectorStoreSettingUpdate(vectorStore.id, vectorStore.vector_store_type)}>
                                                    利用する
                                                </Button>
                                            )}
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    )}
                </Box>
            </Container>
        </div>
    );
};

export default Setting;
