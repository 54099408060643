// theme.ts
import { extendTheme, Link } from "@chakra-ui/react";

const theme = extendTheme({
    colors: {
        brand: {
            100: "#f7fafc",
            300: "#E5E7EB",
            500: "#3B82F6",
            700: "#1E3A8A",
            900: "#1a202c",
        },
    },
    fonts: {
        heading: "M PLUS 1p",
        body: "M PLUS 1p",
    },
    components: {
        Text: {
            baseStyle: {
                whiteSpace: "pre-wrap",
            },
        },
        Button: {
            baseStyle: {
                fontWeight: "bold",
            },
            defaultProps: {
                colorScheme: "blue",
            },
        },
        Link: {
            baseStyle: {
                textDecoration: "underline",
                _hover: {
                    textDecoration: "none",
                },
            },
        },
        Container: {
            baseStyle: {
                maxW: "980px",
                padding: "1.5rem 0",
            },
        },
    },
});

export default theme;
