import { useState, useEffect } from "react";
import axios from "axios";

const useThreads = () => {
    const [threads, setThreads] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_APP_URL}/api/thread/`
                    , { withCredentials: true }
                );
                setThreads(response.data);
            } catch (err) {
                setError("データの取得に失敗しました。");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return { threads, loading, error };
};

export default useThreads;
